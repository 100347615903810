<template>
	<P5
		:sketch="sketch"
		v-on="{mousewheel}"
		id="background"
		:style="{opacity: backgroundOpacity}"
	/>
</template>

<script setup>
import { onBeforeUnmount, watch, ref } from 'vue'
import { VERTICES_AMOUNT, NOISE_SCALE, Z_SPEED, MOUSE_FORCE, MIN_OPACITY, MAX_OPACITY, MIN_WIDTH_TO_RUN } from './backgroundOptions'

const props = defineProps({
	active: Boolean
})

let sketchReference = null
let xOffset = 1000
let yOffset = 1000
let zOffset = 1000
let xSpeed = 1
let ySpeed = 0
let w = null
let h = null
let r = null
let amplitude = null

const backgroundOpacity = ref(MIN_OPACITY)

onBeforeUnmount(() => {
	window.removeEventListener('resize', windowResized)
	window.removeEventListener('scroll', fadeBackground)
})

const sketch = (p5) => {
	p5.setup = () => {
		sketchReference = p5
		fadeBackground()
		setSizes()
		p5.frameRate(30)
		p5.createCanvas(w, h)

		window.addEventListener('resize', windowResized)
		window.addEventListener('scroll', fadeBackground)
	}

	p5.draw = () => {
		p5.colorMode(p5.HSB)
		// change noise direction with mouse
		if (p5.mouseX || p5.mouseY) {
			const mouseVector = p5.createVector(p5.mouseX / w - 0.5, p5.mouseY / h - 0.5)
			mouseVector.mult(MOUSE_FORCE)
			xSpeed = mouseVector.x
			ySpeed = mouseVector.y
		}

		p5.background(228, 100, 42)
		const fillColour = p5.color(255, 255, 255)
		fillColour.setAlpha(0.5)

		// shape 1:
		p5.push()
		p5.translate(w / 2, h / 2)

		p5.noStroke()
		p5.fill(fillColour)

		p5.beginShape()
		for (let a = 0; a < p5.TWO_PI; a += p5.TWO_PI / VERTICES_AMOUNT) {
			const x = r * p5.sin(a)
			const y = r * p5.cos(a)

			const newX = x + (
				p5.noise(
					((xOffset + x) / NOISE_SCALE),
					((yOffset + y) / NOISE_SCALE),
					zOffset) * amplitude * p5.sin(a))

			const newY = y + (
				p5.noise(
					((xOffset + x) / NOISE_SCALE),
					((yOffset + y) / NOISE_SCALE),
					zOffset) * amplitude * p5.cos(a))
			p5.vertex(newY, newX)
		}
		p5.endShape()

		p5.pop()

		// shape 2
		p5.push()
		p5.translate(w / 2, h / 2)

		p5.noStroke()
		p5.fill(fillColour)

		p5.beginShape()
		for (let a = 0; a < p5.TWO_PI; a += p5.TWO_PI / VERTICES_AMOUNT) {
			const x = r * p5.sin(a)
			const y = r * p5.cos(a)

			const newX = x + (
				p5.noise(
					((xOffset + x) / NOISE_SCALE),
					((yOffset + y) / NOISE_SCALE),
					zOffset) * amplitude * p5.sin(a))

			const newY = y + (
				p5.noise(
					((xOffset + x) / NOISE_SCALE),
					((yOffset + y) / NOISE_SCALE),
					zOffset) * amplitude * p5.cos(a))
			p5.vertex(newX, newY)
		}
		p5.endShape()

		p5.pop()

		// update noise offsets
		zOffset += Z_SPEED
		xOffset += xSpeed
		yOffset += ySpeed
	}
}

watch(() => props.active, () => {
	setActivity(props.active)
})

function mousewheel () {
	fadeBackground()
}

function checkSize () {
	if (window.innerWidth >= MIN_WIDTH_TO_RUN) {
		sketchReference.loop()
	} else {
		sketchReference.noLoop()
	}
}

function setSizes () {
	checkSize()
	w = window.innerWidth
	h = window.innerHeight
	r = (w < h) ? w / 2.5 : h / 4
	amplitude = w / 2
}

function windowResized () {
	setSizes()
	sketchReference.resizeCanvas(w, h)
}

function fadeBackground () {
	const currentScroll = window.scrollY
	const maxScroll = document.body.scrollHeight - window.innerHeight
	const scrollPercent = currentScroll / maxScroll
	const opacity = (MAX_OPACITY - MIN_OPACITY) * scrollPercent + MIN_OPACITY
	backgroundOpacity.value = opacity
}

function setActivity (isActive) {
	if (isActive) {
		sketchReference.loop()
	} else {
		sketchReference.noLoop()
	}
}

</script>

<style lang="scss">
html, body {
	background: #101731;
}
#background {
	position: fixed;
	z-index: 0;
	top: 0; right: 0; bottom: 0; left: 0;
}
</style>
