<template>
	<section id="projects" ref="projectsContainer" @wheel="projectsContainerScrolled">
		<div class="button-container" ref="playCard">
			<div class="card" @click="viewAllSelected">
				<a class="btn btn-play"><img src="../../assets/images/icon-view-all.svg" alt=""> View all<br> projects</a>
			</div>
		</div>
		<div v-for="(project, index) in projects" :key="project.name" ref="projects" class="project" @click="projectSelected(index, project)">
			<div class="thumbnail card" :style="{ background: project.colour }">
				<img :src="project.logo" :alt="project.client">
			</div>
			<div class="meta">
				<span class="client">{{ project.name }}</span> <span class="description">{{ project.description }}</span>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		projects: {
			type: Array,
			required: true
		},
		isTouch: {
			type: Boolean,
			required: true
		}
	},
	data () {
		return {
			projectsWidth: 0
		}
	},
	mounted () {
		this.setProjectsWidth()
		window.addEventListener('resize', this.windowResized)
	},
	beforeUnmount () {
		window.removeEventListener('resize', this.windowResized)
	},
	methods: {
		windowResized (event) {
			this.setProjectsWidth()
		},
		setProjectsWidth () {
			this.projectsWidth = 0
			if (this.$refs.playCard) {
				this.projectsWidth += this.$refs.playCard.offsetWidth
			}
			this.$refs.projects.forEach((projectCard) => {
				this.projectsWidth += projectCard.offsetWidth
			})
		},
		projectsContainerScrolled (event) {
			// disable side scroll on touch devices or if no scroll is needed
			if (this.isTouch || (this.projectsWidth < this.$refs.projectsContainer.offsetWidth)) {
				return
			}

			// release side scroll if it's reached the sides of the container
			if (event.deltaY < 0) {
				// if the amount scrolled on the left is 0, release vertical scroll upwards
				if (this.$refs.projectsContainer.scrollLeft === 0) {
					return
				}
			} else if (event.deltaY > 0) {
				// if the amount scrolled on the left === width of carousel minus the screen resolution, continue scrolling vertically downwards
				if (this.$refs.projectsContainer.scrollLeft === (this.projectsWidth - this.$refs.projectsContainer.offsetWidth)) {
					return
				}
			}

			// translate vertical to horizontal scroll
			if (event.wheelDeltaX > -50 && event.wheelDeltaX < 50) {
				this.$refs.projectsContainer.scrollLeft += event.deltaY
				event.preventDefault()
			}
		},
		projectSelected (index, project) {
			this.$emit('projectSelected', index)
			this.$gtag.event('Project Card Clicked', {
				event_category: 'Site Navigation',
				event_label: project.name + ' Card Clicked'
			})
		},
		viewAllSelected () {
			this.$emit('viewAllSelected')
			this.$gtag.event('View All Projects Button Clicked', {
				event_category: 'Site Navigation',
				event_label: 'View All Projects Button Clicked'
			})
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

#projects {
	overflow-x: scroll;
	max-width: 100%;
	display: flex;
	align-items: top;
	padding-top: 4.5 * $content-base-padding-sm;

	@media screen and (min-width: $breakpoint-min-md) {
		padding-top: 6 * $content-base-padding-md;
	}

	@media screen and (min-width: $breakpoint-min-lg) {
		padding-top: 2.75 * $content-base-padding-lg;
		align-items: center;
	}

	@media screen and (min-width: $breakpoint-min-xl) {
		padding-top: 4.5 * $content-base-padding-lg;
		align-items: center;
	}

	//scrollbar
	-ms-overflow-style: none;
	-moz-scrollbar-width: none;
	&::-webkit-scrollbar { display: none; }

	$card-width-sm: 260px;
	$card-height-sm: 370px;
	$card-width-md: 300px;
	$card-height-md: 430px;
	$card-width-lg: 350px;
	$card-height-lg: 500px;

	.card {
		width: $card-width-sm;
		height: $card-height-sm;

		@media screen and (min-width: $breakpoint-min-md) {
			width: $card-width-md;
			height: $card-height-md;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			width: $card-width-lg;
			height: $card-height-lg;
		}
	}

	.button-container {
		display: none;

		@media screen and (min-width: $breakpoint-min-md) {
			display: block;
			text-align: center;
			padding-left: $content-base-padding-md;
			margin-left: auto;

			.card {
				background: rgba(255,255,255,0.1);
				border-radius: 20px;
				cursor: pointer;
				transition: all 0.15s ease-out;

				&:hover {
					@media screen and (max-width: $breakpoint-max-md) {
						transform: scale(1.025);
						transition: all 0.15s ease-out;
					}
					.btn {
						opacity: 1;
					}
				}
			}
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			padding-left: $content-base-padding-lg;

			.card {
				width: auto;
				height: auto;
				background: transparent;
			}
		}

		.btn {
			width: $card-width-sm;
			display: block;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			line-height: 1.25rem;
			text-align: center;
			opacity: 0.5;

			@media screen and (min-width: $breakpoint-min-md) {
				width: $card-width-md;
			}

			@media screen and (min-width: $breakpoint-min-lg) {
				width: $card-width-lg;
			}

			img {
				display: block;
				margin: 0 auto 15px;
			}
		}
	}

	.project {
		width: $card-width-sm;
		cursor: pointer;
		padding-left: $content-base-padding-sm;

		&:last-child {
			padding-right: $content-base-padding-sm;
			margin-right: auto;
		}

		@media screen and (min-width: $breakpoint-min-md) {
			width: $card-width-md;
			padding-left: $content-base-padding-md;
			&:last-child { padding-right: $content-base-padding-md; }
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			width: $card-width-lg;
			padding-left: $content-base-padding-lg;
			&:last-child { padding-right: $content-base-padding-lg; }
		}

		.thumbnail {
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.15s ease-out;

			img {
				width: 60%;
				max-height: 30%;
				transition: all 0.15s ease-out;
			}
		}

		.meta {
			margin-top: 20px;
			font-size: 0.95rem;
			line-height: 1.2rem;

			@media screen and (min-width: $breakpoint-min-md) {
				font-size: 1.125rem;
				line-height: 1.45rem;
			}

			.description {
				opacity: 0.5;
			}
		}

		&:hover {
			.thumbnail {
				transition: all 0.15s ease-out;
				transform: scale(1.025);

				img {
					transition: all 0.15s ease-out;
					transform: scale(1.05);
				}
			}
		}
	}
}
</style>
