<template>
	<div id="project">
		<div id="modal" ref="modal" @mousemove="modalControlHoverHandler">
			<app-modal-controls
				:project-name="currentProject.name"
				:project-slides-count="currentProject.slides.length"
				:current-project-index="currentProjectIndex"
				:current-slide-index="currentSlideIndex"
				@close="closeModal"
				@previous="previousSlide"
				@next="nextSlide"
			/>
			<div
				id="slides"
				:class="{ 'project-switching': projectSwitch }"
				:style="{ background: transitionBackground }"
			>
				<div id="click-overlay">
					<div id="left" @click="previousSlide" />
					<div id="right" @click="nextSlide" />
				</div>
				<app-project-slide
					v-for="(slide, index) in currentProject.slides"
					:slide="slide"
					:index="index"
					:current-slide-index="currentSlideIndex"
					:key="index"
					@videoEnded="videoEnded"
				/>
			</div>
		</div>
		<div id="overlay" @click="closeModal" />
	</div>
</template>

<script>
import db from '../../store/db'
import ProjectModalControls from './ProjectModalControls.vue'
import ProjectSlide from './ProjectSlide.vue'

const CHANGE_PREV = 'prev'
const CHANGE_NEXT = 'next'
const UI_HIDDEN_CLASS = 'ui-hidden'
const UI_HINT_CLASS = 'ui-hint'
const DEFAULT_TRANSITION_BACKGROUND = '#000'

export default {
	components: {
		appModalControls: ProjectModalControls,
		appProjectSlide: ProjectSlide
	},
	data () {
		return {
			currentSlideIndex: 0,
			currentProjectIndex: null,
			uiHiddenTimeout: null,
			projectSwitch: false,
			transitionBackground: '#000'
		}
	},
	computed: {
		currentSlide () {
			return this.currentProject.slides[this.currentSlideIndex]
		},
		currentProject () {
			return db.projects[this.currentProjectIndex]
		}
	},
	created () {
		this.setProject(this.resolveProjectIndexFromSlug(this.$route.params.slug))
		window.addEventListener('keydown', this.setUpKeyboardEvents)
	},
	beforeRouteUpdate (to, from, next) {
		this.setProject(this.resolveProjectIndexFromSlug(to.params.slug))
		next()
	},
	mounted () {
		this.projectSwitch = false
		document.body.classList.add('modal-open')
		this.autohideModalControls()
		this.displayNavigationHint()
	},
	beforeUnmount () {
		document.body.classList.remove('modal-open')
		window.removeEventListener('keydown', this.setUpKeyboardEvents)
	},
	methods: {
		loadSlide (index) {
			this.currentSlideIndex = index
			this.$gtag.event('Load slide', {
				event_category: 'Project view: ' + this.currentProject.name,
				event_label: 'Slide index: ' + index
			})
		},
		nextSlide () {
			if (this.currentSlideIndex === (this.currentProject.slides.length - 1)) {
				this.nextProject()
			} else {
				this.loadSlide(this.currentSlideIndex + 1)
			}
		},
		previousSlide () {
			if (this.currentSlideIndex <= 0) {
				this.previousProject()
			} else {
				this.loadSlide(this.currentSlideIndex - 1)
			}
		},
		setProject (index) {
			this.currentProjectIndex = index
		},
		closeModal () {
			this.$emit('modalClosed')
			this.$router.push('/')
		},
		previousProject () {
			this.changeProject(this.currentProjectIndex - 1, CHANGE_PREV)
		},
		nextProject () {
			this.changeProject(this.currentProjectIndex + 1, CHANGE_NEXT)
		},
		async changeProject (projectIndex, type) {
			if (projectIndex < 0) {
				this.currentProjectIndex = 0
				this.currentSlideIndex = 0
			} else if (projectIndex === db.projects.length) {
				this.closeModal()
			} else {
				this.projectSwitch = true

				await this.$router.push({ name: 'project', params: { slug: db.projects[projectIndex].slug } })

				if (type === CHANGE_NEXT) {
					const bg = db.projects[projectIndex].slides[0].backgroundColor || DEFAULT_TRANSITION_BACKGROUND
					this.transitionBackground = bg
					this.loadSlide(0)
				} else if (type === CHANGE_PREV) {
					// go to the last slide of the previous project which is already set as a project index
					const bg = db.projects[projectIndex].slides.slice(-1).backgroundColor || DEFAULT_TRANSITION_BACKGROUND
					this.transitionBackground = bg
					this.loadSlide(db.projects[projectIndex].slides.length - 1)
				}

				const vue = this
				setTimeout(() => {
					vue.projectSwitch = false
				}, 1000)
			}
		},
		resolveProjectIndexFromSlug (slug) {
			return db.projects.findIndex(project => project.slug === slug)
		},
		setUpKeyboardEvents (event) {
			switch (event.key) {
			case 'ArrowLeft':
				this.previousSlide()
				break
			case 'ArrowRight':
				this.nextSlide()
				break
			case 'Escape':
				this.closeModal()
				break
			}
		},
		modalControlHoverHandler (event) {
			clearTimeout(this.uiHiddenTimeout)
			if (this.$refs.modal) {
				this.$refs.modal.classList.remove(UI_HIDDEN_CLASS)
			}
			this.autohideModalControls()
		},
		videoEnded (videoSlideIndex) {
			if (videoSlideIndex === this.currentSlideIndex) {
				this.nextSlide()
			}
		},
		autohideModalControls () {
			const vue = this
			this.uiHiddenTimeout = setTimeout(() => {
				if (vue.$refs.modal) {
					vue.$refs.modal.classList.add(UI_HIDDEN_CLASS)
				}
			}, 1000)
		},
		displayNavigationHint () {
			if (this.$refs.modal) {
				this.$refs.modal.classList.add(UI_HINT_CLASS)
			}

			const vue = this
			setTimeout(() => {
				if (vue.$refs.modal) {
					vue.$refs.modal.classList.remove(UI_HINT_CLASS)
				}
			}, 2500)
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

body.modal-open {
	overflow: hidden;
	#projects { overflow: hidden; }
}

#project {
	#modal {
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 5;
		top: 0; right: 0; bottom: 0; left: 0;

		@media screen and (min-width: $breakpoint-min-lg) {
			width: 90%;
			height: 85%;
			max-width: 1200px;
			max-height: 800px;
			margin: auto;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			&.ui-hidden {
				.modal-control {
					opacity: 0;
					transition: opacity 0.75s ease-out;
				}
			}
		}

		@media screen and (max-width: $breakpoint-max-md) {
			&.ui-hint {
				.modal-arrow {
					transition: opacity 0.75s ease-out;
					display: block;
					opacity: 0.5;
				}
			}
		}

		#slides {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: transparent;
			transition: background 0.5s;
			overflow: hidden;

			@media screen and (min-width: $breakpoint-min-lg) {
				-webkit-mask-image: -webkit-radial-gradient(white, black);
				-webkit-backface-visibility: hidden;
				border-radius: 20px;
			}

			&.project-switching {
				.slide-content {
					opacity: 0;
					transition: all 0.1s;
				}
			}

			.slide-content {
				opacity: 1;
				transition: all 1s;
			}
		}

		#click-overlay div {
			position: absolute;
			top: 0; bottom: 0;
			z-index: 6;

			&#left {
				left: 0;
				width: 30%;
			}

			&#right {
				right: 0;
				width: 70%;
			}
		}
	}

	#overlay {
		position: fixed;
		top: 0; right: 0; bottom: 0; left: 0;
		background: rgba(0,0,0,0.75);
		z-index: 4;
	}
}
</style>
