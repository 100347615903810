import { createRouter, createWebHashHistory } from 'vue-router'
import AppHome from '../components/AppHome.vue'
import ProjectModal from '../components/projects/ProjectModal.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: AppHome
	},
	{
		path: '/projects/:slug',
		name: 'project',
		component: ProjectModal
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router
