import 'reset-css'
import 'flexboxgrid'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import p5vue from 'p5vue'
import VueGtag from 'vue-gtag'
import VueSmoothScroll from 'v-smooth-scroll'

const app = createApp(App)

app.use(router)

app.use(p5vue)

app.use(VueGtag, {
	config: { id: 'G-930KGDMG9Q' }
}, router)

app.use(VueSmoothScroll)

app.mount('#app')
