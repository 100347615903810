<template>
	<section id="profile">
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-8 col-sm-offset-3 col-md-7 col-md-offset-4">
					<p>I'm an all-round product specialist with a multidisciplinary skillset in design, development, product management, and marketing. I bring more than 10 years of experience in agencies and product companies across healthcare, advertising, and tech.</p>
					<p>I've worked with companies like:</p>
				</div>
			</div>
		</div>

		<div id="logos">
			<img v-if="displayWideLogos" src="../assets/images/logos-wide.svg" alt="Heineken, A1, Telenor, Visa, Canon, Condé Nast, Daye, Mini, KFC, Finlandia Vodka, Jack Daniel's, Renault">
			<img v-else src="../assets/images/logos-narrow.svg" alt="Heineken, A1, Telenor, Visa, Canon, Condé Nast, Daye, Mini, KFC, Finlandia Vodka, Jack Daniel's, Renault">
		</div>

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-8 col-sm-offset-3 col-md-7 col-md-offset-4">
					<p>I'm constantly learning and exploring new fields, which allows me to combine disciplines and bring new perspectives to the table.</p>
				</div>
			</div>

			<div id="services" class="row">
				<div class="col-sm-10 col-sm-offset-1">
					<div class="container-fluid">
						<div class="row">
							<div class="col-sm-6">
								<div class="service">
									<span class="title">Product Development</span>
									<span class="description">Combining design sprints, an iterative user testing approach, and a wide range of project management techniques to bring product ideas to life.</span>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="service">
									<span class="title">User Experience Design</span>
									<span class="description">Structuring and visualising processes and data into functional and usable digital products and interfaces.</span>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="service">
									<span class="title">Visual Design & Marketing</span>
									<span class="description">Years of experience in the advertising industry and a graphic design degree give me a creative commercial edge.</span>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="service">
									<span class="title">Full-stack Development</span>
									<span class="description">Coming from a web and mobile app background, I have a deep understanding of how software is built.</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-8 col-sm-offset-3 col-md-7 col-md-offset-4">
					<p>My mission is to create designs and processes that improve people’s daily lives. If you're looking for someone like me, please get in touch and share <span class="nowrap">your ideas.</span></p>
					<a href="mailto:Julian Kuntorov <julian@kuntorov.com>" target="_blank" rel="noreferrer" class="btn" @click="emailClicked"><img src="../assets/images/icon-send-email.svg" alt=""> Send me an email</a>
					<a class="btn" href="https://isitgoodenough.substack.com" target="_blank" rel="noreferrer" @click="blogClicked"><img src="../assets/images/icon-blog.svg" alt=""> Check out my blog</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data () {
		return {
			displayWideLogos: null
		}
	},
	created () {
		window.addEventListener('resize', this.setDisplayWideLogos)
	},
	mounted () {
		this.setDisplayWideLogos()
	},
	beforeUnmount () {
		window.removeEventListener('resize', this.setDisplayWideLogos)
	},
	methods: {
		setDisplayWideLogos () {
			if (window.innerWidth > 550) {
				this.displayWideLogos = true
			} else {
				this.displayWideLogos = false
			}
		},
		emailClicked () {
			this.$gtag.event('Email Clicked', {
				event_category: 'External Links',
				event_label: 'Bottom Email Link Clicked'
			})
		},
		blogClicked () {
			this.$gtag.event('Blog Clicked', {
				event_category: 'External Links',
				event_label: 'Substack Link Clicked'
			})
		}
	}
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

#profile {
	margin: $content-base-padding-sm * 3 auto;

	@media screen and (min-width: $breakpoint-min-md) {
		margin: $content-base-padding-md * 3 auto;
	}

	@media screen and (min-width: $breakpoint-min-lg) {
		margin: $content-base-padding-lg * 3 auto;
	}

	@media screen and (min-width: $breakpoint-min-xxl) {
		margin: $content-base-padding-lg * 4 auto;
	}

	.container-fluid {
		max-width: 500px;
		padding-left: $content-base-padding-sm * 1.5;
		padding-right: $content-base-padding-sm * 1.5;

		@media screen and (min-width: $breakpoint-min-md) {
			max-width: 960px;
			padding-left: $content-base-padding-md;
			padding-right: $content-base-padding-md;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			padding-left: $content-base-padding-lg;
			padding-right: $content-base-padding-lg;
		}
	}

	p {
		font-size: 1.1rem;
		line-height: 1.6rem;
		margin-bottom: $content-base-padding-sm;

		@media screen and (min-width: $breakpoint-min-md) {
			font-size: 1.25rem;
			line-height: 1.85rem;
			margin-bottom: $content-base-padding-md;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			font-size: 1.4rem;
			line-height: 2rem;
			margin-bottom: $content-base-padding-lg;
		}
	}

	#logos {
		max-width: 960px;
		margin: auto;
		margin-top: $content-base-padding-sm;
		margin-bottom: $content-base-padding-sm * 3;

		padding-left: $content-base-padding-sm * 1.5;
		padding-right: $content-base-padding-sm * 1.5;

		@media screen and (min-width: $breakpoint-min-md) {
			max-width: 960px;
			padding-left: $content-base-padding-md;
			padding-right: $content-base-padding-md;
			margin-bottom: $content-base-padding-md * 3;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			padding-left: $content-base-padding-lg;
			padding-right: $content-base-padding-lg;
			margin-bottom: $content-base-padding-lg * 3;
		}

		img {width: 100%;}
	}

	#services {
		margin-top: $content-base-padding-sm;
		margin-bottom: $content-base-padding-sm * 1.5;

		@media screen and (min-width: $breakpoint-min-md) {
			margin-bottom: $content-base-padding-md * 1.5;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			margin-bottom: $content-base-padding-lg * 1.5;
		}

		@media screen and (max-width: $breakpoint-max-sm) {
			.container-fluid, .row {
				margin: 0; padding: 0;
			}
		}

		.service {
			padding-top: 15px;
			position: relative;
			margin-bottom: $content-base-padding-sm * 1.5;
			font-size: 1rem;
			line-height: 1.5rem;

			@media screen and (min-width: $breakpoint-min-md) {
				font-size: 1.075rem;
				line-height: 1.55rem;
				padding-top: 20px;
				margin-bottom: $content-base-padding-md * 1.5;
				margin-left: $content-base-padding-md;
			}

			@media screen and (min-width: $breakpoint-min-lg) {
				font-size: 1.125rem;
				line-height: 1.7rem;
				margin-bottom: $content-base-padding-lg * 1.5;
				margin-left: $content-base-padding-lg;
			}

			&:before {
				width: 40px; height: 2px;
				position: absolute;
				top: 0; left: 0;
				background: #fff;
				content: '';
			}

			.description {
				margin-left: 5px;
				opacity: 0.5;
			}
		}
	}

	a.btn {
		display: block;
		margin-bottom: 20px;
	}
}
</style>
