const projects = [
	{
		name: 'Daye Platform',
		client: 'Daye',
		slug: 'daye-platform',
		description: 'Laying the groundwork of a gynaecological health platform',
		colour: '#EBA920',
		logo: require('../assets/projects/daye-platform/daye-logo.svg'),
		slides: [
			{
				type: 'video',
				video: require('../assets/projects/daye-platform/daye-video-logo.mp4')
			},
			{
				type: 'text',
				content: 'The Daye website reflects the company’s evolution, as it moves its focus away from its hero product — the CBD tampon, and into a unified gynae health platform with products and services that cover not only menstrual, but also hormonal and reproductive health.',
				backgroundColor: '#001D3B'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/daye-platform/daye-platform-landscape-1.jpg'),
					portrait: require('../assets/projects/daye-platform/daye-platform-portrait-1.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/daye-platform/daye-platform-landscape-2.jpg'),
					portrait: require('../assets/projects/daye-platform/daye-platform-portrait-2.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/daye-platform/daye-platform-landscape-3.jpg'),
					portrait: require('../assets/projects/daye-platform/daye-platform-portrait-3.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/daye-platform/daye-platform-landscape-4.jpg'),
					portrait: require('../assets/projects/daye-platform/daye-platform-portrait-4.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/daye-platform/daye-platform-landscape-5.jpg'),
					portrait: require('../assets/projects/daye-platform/daye-platform-portrait-5.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/daye-platform/daye-platform-landscape-6.jpg'),
					portrait: require('../assets/projects/daye-platform/daye-platform-portrait-6.jpg')
				}
			},
			{
				type: 'credits',
				role: 'Product design & testing, product management',
				credits: 'Created in tight collaboration with the rest of the Daye team',
				backgroundColor: '#001D3B'
			}
		]
	},
	{
		name: 'Attaim App',
		client: 'Attaim',
		slug: 'attaim-app',
		description: 'Building a platform for professional development',
		colour: '#FA531D',
		logo: require('../assets/projects/attaim-app/attaim-logo.svg'),
		slides: [
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/attaim-app/attaim-app-intro-landscape.jpg'),
					portrait: require('../assets/projects/attaim-app/attaim-app-intro-portrait.jpg')
				}
			},
			{
				type: 'text',
				content: 'Attaim is a professional development platform that helps people discover their careers’ potential and achieve their aspirations through guided reflections and AI-assisted learning.',
				textColor: '#FFFBF5',
				backgroundColor: '#242220',
				background: {
					landscape: require('../assets/projects/attaim-app/attaim-app-background-landscape.jpg'),
					portrait: require('../assets/projects/attaim-app/attaim-app-background-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/attaim-app/attaim-app-landscape-1.jpg'),
					portrait: require('../assets/projects/attaim-app/attaim-app-portrait-1.jpg')
				}
			},
			{
				type: 'credits',
				role: 'Solo Founder',
				credits: 'Responsible for developing both the product and the business. Currently a work in progress.',
				textColor: '#FFFBF5',
				backgroundColor: '#242220',
				background: {
					landscape: require('../assets/projects/attaim-app/attaim-app-background-landscape.jpg'),
					portrait: require('../assets/projects/attaim-app/attaim-app-background-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			}
		]
	},
	{
		name: 'Tennis App Concept',
		client: 'Side Project',
		slug: 'tennis-app',
		description: 'Social networking app for tennis players (side project)',
		colour: '#95C829',
		logo: require('../assets/projects/tennis-app/tennis-app-logo.svg'),
		slides: [
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/tennis-app/tennis-app-intro-landscape.jpg'),
					portrait: require('../assets/projects/tennis-app/tennis-app-intro-portrait.jpg')
				}
			},
			{
				type: 'text',
				content: 'As a side project, I decided to explore the design of a simple but engaging social network for tennis players. Its purpose would be to connect friends who love playing the sport, track basic stats, and make it easier to find new partners.',
				textColor: '#fff',
				backgroundColor: '#141922'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/tennis-app/tennis-app-landscape-1.jpg'),
					portrait: require('../assets/projects/tennis-app/tennis-app-portrait-1.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/tennis-app/tennis-app-landscape-2.jpg'),
					portrait: require('../assets/projects/tennis-app/tennis-app-portrait-2.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/tennis-app/tennis-app-landscape-3.jpg'),
					portrait: require('../assets/projects/tennis-app/tennis-app-portrait-3.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/tennis-app/tennis-app-landscape-4.jpg'),
					portrait: require('../assets/projects/tennis-app/tennis-app-portrait-4.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/tennis-app/tennis-app-landscape-5.jpg'),
					portrait: require('../assets/projects/tennis-app/tennis-app-portrait-5.jpg')
				}
			},
			{
				type: 'credits',
				role: 'Product Designer',
				credits: 'This tennis app is a passion project, currently a work in progress.',
				textColor: '#fff',
				backgroundColor: '#141922'
			}
		]
	},
	{
		name: 'The Focus',
		client: 'Heineken',
		slug: 'heineken-the-focus',
		description: 'An interactive experience to find the most concentrated football fans',
		colour: '#00D489',
		logo: require('../assets/projects/heineken-the-focus/heineken-logo.svg'),
		slides: [
			{
				type: 'video',
				video: require('../assets/projects/heineken-the-focus/thefocus-video-logo.mp4')
			},
			{
				type: 'text',
				content: 'As a sponsor of the UEFA Champions League, Heineken had a very important message to all football fans: don’t even blink. The matches can be so dynamic, that even a small distraction could make you miss a game changer.',
				backgroundColor: '#10182C'
			},
			{
				type: 'text',
				content: 'The Focus is an interactive experience, in which Heineken challenged football fans to stare down Andrea Pirlo, a player who is famous for his concentration on the field, rewarding the ones who did best with a pass to an exclusive event.',
				background: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-gradient-landscape.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-gradient-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'video',
				video: require('../assets/projects/heineken-the-focus/thefocus-video-gameplay.mp4')
			},
			{
				type: 'text',
				content: 'We used facial recognition technology to track which players managed to stay the longest without blinking, through the cameras of their desktop and mobile devices.',
				background: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-gradient-landscape.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-gradient-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'video',
				video: require('../assets/projects/heineken-the-focus/thefocus-video-tech.mp4')
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-ui-landscape-1.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-ui-portrait-1.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-ui-landscape-2.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-ui-portrait-2.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-ui-landscape-3.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-ui-portrait-3.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-ui-landscape-4.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-ui-portrait-4.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-ui-landscape-5.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-ui-portrait-5.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-ui-landscape-6.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-ui-portrait-6.jpg')
				}
			},
			{
				type: 'text',
				content: 'The game was addictive: In the span of 7 days, almost 40,000 staredowns were held, each user trying for more than 5 minutes on average.',
				background: {
					landscape: require('../assets/projects/heineken-the-focus/thefocus-gradient-landscape.jpg'),
					portrait: require('../assets/projects/heineken-the-focus/thefocus-gradient-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'credits',
				role: 'User experience and interface design, development coordination',
				credits: 'Created with the team at NEXT-DC',
				backgroundColor: '#10182C'
			}
		]
	},
	{
		name: 'Finding Emo',
		client: 'Telenor',
		slug: 'telenor-finding-emo',
		description: 'Making teenagers realise the importance of online privacy',
		colour: '#4B57C9',
		logo: require('../assets/projects/telenor-finding-emo/telenor-logo.svg'),
		slides: [
			{
				type: 'video',
				video: require('../assets/projects/telenor-finding-emo/findingemo-logo.mp4'),
				backgroundColor: '#4B57C9'
			},
			{
				type: 'text',
				content: 'Teenagers put all kinds of content on social media, without considering the sensitivity of that information, or who is able to see it. Telenor decided to address that issue for Safer Internet Day, by showing them the other side of things.',
				backgroundColor: '#222A73'
			},
			{
				type: 'text',
				content: 'Finding Emo is an online experience revolving around a kid called Emo — a high school student who shared stuff online like everyone else. Except that he wasn’t real, and the audience didn’t know that from the start.',
				background: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-background-landscape.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-background-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'video',
				video: require('../assets/projects/telenor-finding-emo/findingemo-face-morph.mp4')
			},
			{
				type: 'text',
				content: 'We created social media profiles for Emo on Facebook, Instagram and Snapchat, and filled them with content. Inside the posts we hid clues with personal information, ranging from the name of his dog, to his school and home address.',
				background: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-background-landscape.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-background-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-social-profiles-landscape.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-social-profiles-portrait.jpg')
				}
			},
			{
				type: 'text',
				content: 'To deliver our message, we collected all that information in an interactive quiz. To complete it, teenagers had to go through all of Emo’s social media profiles and find the hidden clues, basically stalking him down.',
				background: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-background-landscape.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-background-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-quiz-road-landscape.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-quiz-road-portrait.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-ui-landscape-1.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-ui-portrait-1.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-ui-landscape-2.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-ui-portrait-2.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-ui-landscape-3.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-ui-portrait-3.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-ui-landscape-4.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-ui-portrait-4.jpg')
				}
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-ui-landscape-5.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-ui-portrait-5.jpg')
				}
			},
			{
				type: 'text',
				content: 'At the end of the quiz, participants who found him were asked what would happen if someone was looking for them. Would they be able to find sensitive informaton that easily for them too?',
				background: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-background-landscape.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-background-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-ui-landscape-6.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-ui-portrait-6.jpg')
				}
			},
			{
				type: 'text',
				content: 'More than a 1000 participants found Emo, some didn’t even realise that he wasn’t real. But all of them understood the importance of online privacy. Emo’s social media profiles even recieved several “thank you” messages from participants.',
				background: {
					landscape: require('../assets/projects/telenor-finding-emo/findingemo-background-landscape.jpg'),
					portrait: require('../assets/projects/telenor-finding-emo/findingemo-background-portrait.jpg')
				},
				backgroundType: 'image',
				backgroundOpacity: 1
			},
			{
				type: 'credits',
				role: 'Concept & campaign strategy, user experience and interface design, development coordination',
				credits: 'Created with the team at NEXT-DC',
				backgroundColor: '#222A73'
			}
		]
	},
	{
		name: 'Pocket Science',
		client: 'Visa',
		slug: 'visa-pocket-science',
		description: 'A fashion collection which you can buy with knowledge, not money',
		colour: '#FF547F',
		logo: require('../assets/projects/visa-pocket-science/visa-logo.svg'),
		slides: [
			{
				type: 'video',
				video: require('../assets/projects/visa-pocket-science/pocketscience-logo.mp4'),
				backgroundColor: '#2AA5A8'
			},
			{
				type: 'text',
				content: 'Financial literacy is very important, especially for young people starting out in life. Realising that, Visa started an initiative to get the subject into the Bulgarian school system. But there was a problem: teens found finance to be quite boring...',
				backgroundColor: '#003A4E'
			},
			{
				type: 'text',
				content: 'We created Pocket Science, a fashion brand that lets teenagers buy clothes with knowledge, not money. Students could learn about finance and solve tests to earn points on a special website, in which they could also get the items from the collection.',
				backgroundColor: '#2AA5A8'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/visa-pocket-science/pocketscience-ui-landscape-1.jpg'),
					portrait: require('../assets/projects/visa-pocket-science/pocketscience-ui-portrait-1.jpg')
				},
				backgroundColor: '#003A4E'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/visa-pocket-science/pocketscience-ui-landscape-2.jpg'),
					portrait: require('../assets/projects/visa-pocket-science/pocketscience-ui-portrait-2.jpg')
				},
				backgroundColor: '#003A4E'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/visa-pocket-science/pocketscience-ui-landscape-3.jpg'),
					portrait: require('../assets/projects/visa-pocket-science/pocketscience-ui-portrait-3.jpg')
				},
				backgroundColor: '#003A4E'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/visa-pocket-science/pocketscience-ui-landscape-4.jpg'),
					portrait: require('../assets/projects/visa-pocket-science/pocketscience-ui-portrait-4.jpg')
				},
				backgroundColor: '#003A4E'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/visa-pocket-science/pocketscience-ui-landscape-5.jpg'),
					portrait: require('../assets/projects/visa-pocket-science/pocketscience-ui-portrait-5.jpg')
				},
				backgroundColor: '#003A4E'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/visa-pocket-science/pocketscience-ui-landscape-6.jpg'),
					portrait: require('../assets/projects/visa-pocket-science/pocketscience-ui-portrait-6.jpg')
				},
				backgroundColor: '#003A4E'
			},
			{
				type: 'image',
				image: {
					landscape: require('../assets/projects/visa-pocket-science/pocketscience-ui-landscape-7.jpg'),
					portrait: require('../assets/projects/visa-pocket-science/pocketscience-ui-portrait-7.jpg')
				},
				backgroundColor: '#003A4E'
			},
			{
				type: 'text',
				content: 'Students from 649 schools in Bulgaria participated in the campaign, solving more than 33 500 tests. On average, they spent 9 minutes on the website, with some users going to extremes — the longest visit was 4.5 hours.',
				backgroundColor: '#2AA5A8'
			},
			{
				type: 'credits',
				role: 'User experience and interface design, development coordination',
				credits: 'Created with the team at NEXT-DC',
				backgroundColor: '#003A4E'
			}
		]
	}
]

export default {
	projects
}
