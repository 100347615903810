<template>
	<main id="content">
		<app-project-carousel
			:projects="projects"
			:is-touch="isTouch"
			@projectSelected="openModal"
			@viewAllSelected="openModal(0)"
		/>
		<profile-info />
	</main>
</template>

<script>
import { globalVars } from '../store/globalVars'
import ProjectCarousel from './projects/ProjectCarousel.vue'
import ProfileInfo from './ProfileInfo.vue'
import db from '../store/db'

export default {
	components: {
		appProjectCarousel: ProjectCarousel,
		profileInfo: ProfileInfo
	},
	computed: {
		projects () {
			return db.projects
		}
	},
	data () {
		return {
			isTouch: true
		}
	},
	mounted () {
		this.isTouch = globalVars.isTouch
	},
	methods: {
		openModal (index) {
			globalVars.backgroundActivity = false
			this.$router.push({ name: 'project', params: { slug: this.projects[index].slug } })
		}
	}
}
</script>

<style lang="scss">

</style>
