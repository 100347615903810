<template>
	<div
		class="project-slide"
		:id="'slide-' + index"
		:class="{current: index === currentSlideIndex}"
		:style="extractStyle(slide)"
	>
		<div class="slide-content modal-text" v-if="slide.type === 'text' && slide.content">
			{{ slide.content }}
		</div>

		<div class="slide-content modal-text meta" v-if="slide.type === 'credits' ">
			<div class="role">
				<span>Role</span> {{ slide.role }}
			</div>
			<div class="credits">
				{{ slide.credits }}
			</div>
		</div>

		<div class="slide-content slide-background-content slide-text-background" v-if="slide.backgroundType == 'image'">
			<img v-if="screenOrientation == ORIENTATION_LANDSCAPE" :src="slide.background.landscape" alt="" :style="{opacity: slide.backgroundOpacity}">
			<img v-if="screenOrientation == ORIENTATION_PORTRAIT" :src="slide.background.portrait" alt="" :style="{opacity: slide.backgroundOpacity}">
		</div>

		<div class="slide-content slide-background-content" v-if="slide.type === 'image' && slide.image">
			<img v-if="screenOrientation == ORIENTATION_LANDSCAPE" :src="slide.image.landscape" alt="">
			<img v-if="screenOrientation == ORIENTATION_PORTRAIT" :src="slide.image.portrait" alt="">
		</div>

		<div class="slide-content slide-background-content" v-if="slide.type === 'video' && slide.video">
			<video :src="slide.video" playsinline mute @ended="currentVideoEnded(index)" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		slide: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		},
		currentSlideIndex: {
			type: Number,
			required: true
		}
	},
	data () {
		return {
			ORIENTATION_LANDSCAPE: 'landscape',
			ORIENTATION_PORTRAIT: 'portrait',
			screenOrientation: null
		}
	},
	watch: {
		currentSlideIndex () {
			if (this.index === this.currentSlideIndex) {
				this.checkForVideo()
			}
		}
	},
	created () {
		window.addEventListener('resize', this.setScreenOrientation)
	},
	mounted () {
		this.setScreenOrientation()
		if (this.index === this.currentSlideIndex) {
			this.checkForVideo()
		}
	},
	beforeUnmount () {
		window.removeEventListener('resize', this.setScreenOrientation)
	},
	methods: {
		setScreenOrientation () {
			if (window.innerWidth > window.innerHeight) {
				this.screenOrientation = this.ORIENTATION_LANDSCAPE
			} else {
				this.screenOrientation = this.ORIENTATION_PORTRAIT
			}
		},
		extractStyle (slide) {
			const style = {}
			if (slide.backgroundColor) {
				style.backgroundColor = slide.backgroundColor
			} else {
				style.backgroundColor = '#000'
			}

			if (slide.textColor) {
				style.color = slide.textColor
			}

			return style
		},
		checkForVideo () {
			if (this.index === this.currentSlideIndex && this.slide.type === 'video') {
				this.$nextTick(() => {
					const slideVideo = document.querySelector('#slide-' + this.index + ' video')
					slideVideo.currentTime = 0
					slideVideo.muted = true
					slideVideo.playsinline = true
					slideVideo.play()
				})
			}
		},
		currentVideoEnded (videoSlideIndex) {
			this.$emit('videoEnded', videoSlideIndex)
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.project-slide {
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	overflow: hidden;
	backface-visibility: hidden;
	mask-image: -webkit-radial-gradient(white, black);
	-webkit-backface-visibility: hidden;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	opacity: 0;
	transition: all 0.5s;
	pointer-events: none;

	@media screen and (min-width: $breakpoint-min-lg) {
		border-radius: 20px;
	}

	&.current {
		opacity: 1;
	}

	.slide-background-content {
		width: 100%; height: 100%;
		z-index: 8;

		img, video {
			width: 100%; height: 100%;
			object-fit: cover;
		}
	}

	.slide-text-background {
		position: absolute;
	}

	.modal-text {
		z-index: 9;
		width: 85%;
		font-size: 1rem;
		line-height: 1.65rem;

		@media screen and (min-width: $breakpoint-min-sm) {
			width: 75%;
			font-size: 1.25rem;
			line-height: 2rem;
		}

		@media screen and (min-width: $breakpoint-min-md) {
			width: 60%;
			font-size: 1.5rem;
			line-height: 2.3rem;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			width: 45%;
			font-size: 1.5rem;
			line-height: 2.35rem;
		}
	}

	.meta {
		.role span {
			opacity: 0.5;
			display: block;
		}

		.credits {
			margin-top: $content-base-padding-lg;
			opacity: 0.5;
		}
	}
}
</style>
