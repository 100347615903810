<template>
	<nav>
		<div class="corner" id="title">
			Julian Kuntorov <span>Product Design & Strategy</span>
		</div>
		<div class="corner" id="email">
			<a href="mailto:Julian Kuntorov <julian@kuntorov.com>" target="_blank" rel="noreferrer" @click="emailClicked">Email</a>
		</div>
		<transition name="fade">
			<div class="corner pointer" id="meta" key="profile" v-if="showScrollHint">
				<a href="#profile" v-smooth-scroll="{ duration: 1000, offset: -120, updateHistory: false }" @click="profileHintClicked"><img src="../assets/images/arrow-down.svg" alt="&darr;"> Profile</a>
			</div>
			<div class="corner" id="meta" key="copyright" v-else>
				Copyright &copy; {{ new Date().getFullYear() }}
			</div>
		</transition>
		<div class="corner icons" id="social">
			<a id="icon-email" target="_blank" rel="noreferrer" href="mailto:Julian Kuntorov <julian@kuntorov.com>" @click="emailClicked"><img src="../assets/images/icon-mail.svg" alt="Email"></a>
			<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/jkuntorov" @click="linkedinClicked"><img src="../assets/images/icon-linkedin.svg" alt="LinkedIn"></a>
			<a target="_blank" rel="noreferrer" href="https://instagram.com/jkuntorov" @click="instagramClicked"><img src="../assets/images/icon-instagram.svg" alt="Instagram"></a>
		</div>
	</nav>
</template>

<script>
export default {
	data () {
		return {
			showScrollHint: true
		}
	},
	created () {
		window.addEventListener('scroll', this.onScroll)
	},
	unmounted () {
		window.removeEventListener('scroll', this.onScroll)
	},
	methods: {
		onScroll () {
			this.showScrollHint = window.scrollY < (window.innerHeight / 2)

			if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
				this.$gtag.event('Page Bottom Reached', {
					event_category: 'Site Navigation',
					event_label: 'Bottom of the Page Reached'
				})
			}
		},
		profileHintClicked () {
			this.$gtag.event('Profile Hint Clicked', {
				event_category: 'Site Navigation',
				event_label: 'Profile Hint Clicked'
			})
		},
		emailClicked () {
			this.$gtag.event('Email Clicked', {
				event_category: 'External Links',
				event_label: 'Navigation Email Link Clicked'
			})
		},
		linkedinClicked () {
			this.$gtag.event('LinkedIn Clicked', {
				event_category: 'External Links',
				event_label: 'Navigation LinkedIn Link Clicked'
			})
		},
		instagramClicked () {
			this.$gtag.event('Instagram Clicked', {
				event_category: 'External Links',
				event_label: 'Navigation Instagram Link Clicked'
			})
		}
	}
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_animation.scss";

nav {
	$nav-font-size-xs: 1rem;
	$nav-font-size-regular: 1.125rem;

	font-size: $nav-font-size-xs;

	@media screen and (min-width: $breakpoint-min-sm) {
		font-size: $nav-font-size-regular;
	}

	.corner {
		position: absolute;
		z-index: 3;

		@media screen and (max-width: $breakpoint-max-sm) {
			&#title { top: $content-base-padding-sm; left: $content-base-padding-sm; }
			&#social { top: $content-base-padding-sm; right: $content-base-padding-sm; }
		}

		@media screen and (max-width: $breakpoint-max-md) {
			&#email { display: none; }
			&#meta { display: none; }
		}

		@media screen and (min-width: $breakpoint-min-sm) {
			&#title { top: $content-base-padding-md; left: $content-base-padding-md; }
			&#social { top: $content-base-padding-md; right: $content-base-padding-md; }
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			position: fixed;
			&#title { top: $content-base-padding-lg; left: $content-base-padding-lg; }
			&#email { top: $content-base-padding-lg; right: $content-base-padding-lg; }
			&#meta { bottom: $content-base-padding-lg; left: $content-base-padding-lg; }
			&#social { top: auto; bottom: $content-base-padding-lg; right: $content-base-padding-lg; }
		}
	}

	#title span {
		opacity: 0.5;
		display: block;
		margin-top: 7px;
		font-size: 0.9rem;

		@media screen and (min-width: $breakpoint-min-sm) {
			display: inline;
			margin-top: 0;
			margin-left: 5px;
			font-size: $nav-font-size-regular;
		}
	}

	#meta {
		img {
			vertical-align: 1px;
			margin-right: 5px;
		}
	}

	.icons {
		margin-right: -15px;
		a { padding: 15px; }
		line-height: 45px;

		@media screen and (min-width: $breakpoint-min-sm) {
			line-height: $nav-font-size-regular;
		}

		@media screen and (min-width: $breakpoint-min-lg) {
			#icon-email { display: none; }
		}
	}
}
</style>
